import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../css/navbar.css';

const Navbar = ()=>{
    const [navbar, setNavbar] = useState({background: '#2b3c55'});
    const [navlink, setNavLink] = useState({color: '#fff'});
    const [togglecolor, setToggleColor] = useState('navbar navbar-expand-lg navbar-dark fixed-top')
    const [logo, setLogo] = useState('https://storage.googleapis.com/complaincebrain-public/compliance-brain-Website/logo.png');
    window.onscroll = ()=>{
        if(window.pageYOffset>=15){
            setNavbar({background: '#fff'});
            setLogo('https://storage.googleapis.com/complaincebrain-public/compliance-brain-Website/logo-colorful.png');
            setNavLink({color: '#2b3c55'});
            setToggleColor('navbar navbar-expand-lg navbar-light fixed-top')
        }
        else if(window.pageYOffset<=15){
            setNavbar({background: '#2b3c55'});
            setLogo('https://storage.googleapis.com/complaincebrain-public/compliance-brain-Website/logo.png');
            setNavLink({color: '#fff'});
            setToggleColor('navbar navbar-expand-lg navbar-dark fixed-top')
        }
    }
    return (
        <>
            <nav className={togglecolor} style={navbar}>
                <div className="container-lg">
                    <a className="navbar-brand" href="/">
                        <img src={logo} alt="Compliance Brain Logo"/>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" id="toggle"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a style={navlink} className="nav-link active" aria-current="page" href="/">Home</a>
                            </li>
                            {/* <li className="nav-item">
                                <a style={navlink} className="nav-link" href="#">Resources</a>
                            </li> */}
                            <li className="nav-item">
                                <a style={navlink} className="nav-link" href="#contact">Contact us</a>
                            </li>
                            <li className="nav-item">
                                {/* <a style={navlink} className="nav-link" href="https://account.dev.cb-compliancebrain.io/">Login</a> */}
                                <a style={navlink} className="nav-link" href="https://account.compliancebrain.io/">Login</a>
                            </li>
                            <li className="nav-item">
                                <a style={navlink} className="nav-link btn" href="#schedule-demo">Schedule A Demo</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar;
