import React, { useEffect, useState } from 'react';
import '../css/footer.css';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';

const Footer = ()=>{
    const [contact, setContact] = useState({
        fname: '',
        lname: '',
        email: '',
        message: ''
    });

    const {fname, lname, email, message} = contact;

    useEffect(()=>{
        if(fname !== '')
            document.getElementById('fname-contact').style.cssText = 'top: -12px;font-size: 14px';
        else
            document.getElementById('fname-contact').style.cssText = 'top: 10px;';
        if(lname !== '')
            document.getElementById('lname-contact').style.cssText = "top: -12px;font-size: 14px";
        else
            document.getElementById('lname-contact').style.cssText = 'top: 10px';
        if(email !== '')
            document.getElementById('email-contact').style.cssText = 'top: -12px;font-size: 14px';
        else
            document.getElementById('email-contact').style.cssText = 'top: 10px';
        if(message !== '')
            document.getElementById("message-contact").style.cssText = 'top: -12px;font-size: 14px';
        else
            document.getElementById('message-contact').style.cssText = 'top: 10px'
    }, [fname, lname, email, message])

    const BackToTop = ()=>{
        let height = window.pageYOffset;
        window.scrollBy(0, -height);
    }

    const success_footer = `
    <div className="success-footer" id="success-footer">
    <div className="box">
        <img src="https://storage.googleapis.com/complaincebrain-public/compliance-brain-Website/Success.png" alt="check" />
    </div>
    <p style={{marginTop: '10px'}}>Thank you for filling the form. We will get back to you soon.</p>
</div>`;

    const Send = (e)=>{
        e.preventDefault();

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(fname === '' || email === '' || message === ''){
            window.alert("Please fill all the required fields");
        }
        else{
            if(re.test(email)){
                document.getElementById("right-col").children[0].outerHTML = success_footer;
            }
            else{
                window.alert("Please Enter Valid Email Address");
            }
        }
    }

    return (
        <>
            <div className="footer" id="contact">
                <div className="contact-form">
                    <div className="left-col">
                        <h1>Contact Us</h1>
                        <div className="line"></div>
                        <p>Fill up the form and our team will get back to you within 24 hours.</p>
                        <ul>
                            <li>
                                <img src="https://storage.googleapis.com/complaincebrain-public/compliance-brain-Website/ContactEmail.png" alt="contact email" />
                                <span>radar@compliancebrain.io</span>
                            </li>
                            <li>
                                <img src="https://storage.googleapis.com/complaincebrain-public/compliance-brain-Website/Contact%20Phone.png" alt="contact phone" />
                                <span>(800) 769-6273</span>
                            </li>
                            <li>
                                <img src="https://storage.googleapis.com/complaincebrain-public/compliance-brain-Website/contact%20location.png" alt="contact location" />
                                <span>16930 E. Palisades Blvd #100 Fountain Hills, AZ 85268</span>
                            </li>
                        </ul>
                    </div>
                    <div className="right-col" id="right-col">
                        <form method="POST" className="form">
                            <div>
                                <input type="text" name="fname" id="fname" value={fname} onChange={(e)=>{setContact({...contact, fname: e.target.value})}} />
                                <label id="fname-contact" for="fname">First Name*</label>
                            </div>
                            <div>
                                <input type="text" name="lname" id="lname" value={lname} onChange={(e)=>{setContact({...contact, lname: e.target.value})}} />
                                <label id="lname-contact" for="lname">Last Name</label>
                            </div>
                            <div>
                                <input type="email" name="email" id="email" value={email} onChange={(e)=>{setContact({...contact, email: e.target.value})}} />
                                <label id="email-contact" for="email">Email*</label>
                            </div>
                            <div>
                                <textarea name="message" id="message"  value={message} onChange={(e)=>{setContact({...contact, message: e.target.value})}}></textarea>
                                <label id="message-contact" for="message">Message*</label>
                            </div>
                            <div>
                                <button onClick={Send} type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                    <div onClick={BackToTop} className="back-to-top">
                        <ArrowUpwardIcon id="icon"/>
                    </div>
                </div>
                <div className="foot">
                    <div className="container-lg">
                        <div className="top">
                            <img src="https://storage.googleapis.com/complaincebrain-public/compliance-brain-Website/logo.png" alt="logo" />
                            <ul className="tabs">
                                <li><a className="active" href="/">Home</a></li>
                                {/* <li><a href="#">Resources</a></li> */}
                                <li><a href="#">Contact us</a></li>
                                {/* <li><a href="https://account.dev.cb-compliancebrain.io/">Login</a></li> */}
                                <li><a href="https://account.compliancebrain.io/">Login</a></li>
                            </ul>
                            <ul className="social-media">
                                <li>
                                <svg xmlns="http://www.w3.org/2000/svg"  shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 640 640"><path d="M326.331 274.255v109.761h181.49c-7.37 47.115-54.886 138.002-181.49 138.002-109.242 0-198.369-90.485-198.369-202.006 0-111.509 89.127-201.995 198.369-201.995 62.127 0 103.761 26.516 127.525 49.359l86.883-83.635C484.99 31.512 412.741-.012 326.378-.012 149.494-.012 6.366 143.116 6.366 320c0 176.884 143.128 320.012 320.012 320.012 184.644 0 307.256-129.876 307.256-312.653 0-21-2.244-36.993-5.008-52.997l-302.248-.13-.047.024z" fill="#5a7184"/></svg>
                                </li>
                                <li><TwitterIcon id="sicon" /></li>
                                <li><InstagramIcon id="sicon" /></li>
                                <li><LinkedInIcon id="sicon" /></li>
                                <li><YouTubeIcon id="sicon" /></li>
                            </ul>
                            <div className="terms">
                                <p><a href="https://compliancebrain.io/">Terms &amp; Conditions</a>     |     <a href="https://compliancebrain.io/">Privacy Policy</a></p>
                            </div>
                        </div>
                        <div className="divider"></div>
                        <div className="bottom">
                            <p>All copyrights reserved © 2021 - Designed &amp; Developed by Compliance Brain</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Footer;
